import ApiMethods from "../../../../ApiManager/ApiMethods";
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  EventNote,
  People,
  ArrowBack,
  CalendarMonth,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PersonIcon from "@mui/icons-material/Person";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "moment/locale/sr";
import moment from "moment";

const BookingCard = ({ booking, children, bookingState }) => {
  const formatTime = (date) => {
    return format(date, "hh:mm a");
  };
  const getSerbianDay = (dateString) => {
    const dayInSerbian = moment(dateString).format("dddd");
    return dayInSerbian.charAt(0).toUpperCase() + dayInSerbian.slice(1);
  };

  console.log(booking);
  const startDate = new Date(booking.start);
  const endDate = new Date(booking.end);
  const formattedStartDate = format(startDate, "yyyy-MM-dd");

  const month = format(startDate, "MMMM");
  const dayAndYear = format(startDate, "dd.yyyy");

  const formattedStart = formatTime(startDate);
  const formattedEnd = formatTime(endDate);

  return (
    <div className="parent-booking-card">
      <div key={booking.id} className="booking-card">
        {booking.status === "canceled" && (
          <div className="booking-date">
            Status:<span style={{ color: "red" }}>Otkazan</span>
          </div>
        )}
        {booking.status === "rescheduled" && (
          <div className="booking-date">
            Status:<span style={{ color: "#97dfff" }}>Odlozen</span>{" "}
          </div>
        )}

        {booking.status === "book" && (
          <div className="booking-date">
            Status:<span style={{ color: "green" }}>Zakazan</span>{" "}
          </div>
        )}
        <div className="booking-card-header">
          {!bookingState ? (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <HistoryIcon style={{ color: "white", fontSize: "24px" }} />
              <span className="booking-date">{getSerbianDay(startDate)}-</span>
              <span className="booking-date">
                {month}
                <span style={{ margin: "0 2px" }}>&nbsp;</span>
                {dayAndYear}
              </span>
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <CalendarMonth style={{ color: "white", fontSize: "24px" }} />
              <span className="booking-date">
                <span className="booking-date">Detalji zakazivanja</span>
              </span>
            </div>
          )}
        </div>
        {bookingState ? (
          <div className="booking-time">
            <span className="time-booking-card">Datum:</span>
            <span className="hour-booking-card">{formattedStartDate}-</span>
            <span className="booking-date">{getSerbianDay(startDate)}</span>
          </div>
        ) : null}

        <div className="booking-time">
          <span className="time-booking-card">Vreme:</span>
          <span className="hour-booking-card">
            {formattedStart} - {formattedEnd}
          </span>
        </div>
        <div className="booking-card-footer">
          <PersonIcon style={{ color: "white" }} />
          <span className="booking-type">Trening</span>
        </div>
      </div>
      {/* <MoreVertIcon
        className="more-icon"
        style={{
          color: "white",
          cursor: "pointer",
          fontSize: "28px", // Adjust this size to create spacing between dots
        }}
      /> */}
    </div>
  );
};

export default BookingCard;
