import React, { useState, useEffect } from "react";

export const SchedulePopover = ({ setShowModal, selectedCards }) => {
  const [scrollDirection, setScrollDirection] = useState("up");
  const [lastScrollY, setLastScrollY] = useState(0);

  // useEffect(() => {
  //     const handleScroll = () => {
  //       const currentScrollY = window.scroll;
  //       if (currentScrollY > lastScrollY) {
  //         setScrollDirection("down");
  //       } else if (currentScrollY < lastScrollY) {
  //         setScrollDirection("up");
  //       }

  //       setLastScrollY(currentScrollY);
  //     };
  //     window.addEventListener("scroll", handleScroll);
  //     return () => {
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }, [lastScrollY]);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollY = window.scrollY;

  //     if (currentScrollY > lastScrollY) {
  //       setScrollDirection("down");
  //     } else if (currentScrollY < lastScrollY) {
  //       setScrollDirection("up");
  //     }

  //     setLastScrollY(currentScrollY);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [lastScrollY]);

  return (
    <div className="selected-slots-div">
      <button
        onClick={setShowModal}
        className={
          scrollDirection == "up" ? "fixed-button" : "fixed-button-scroll"
        }
      >
        Izabrani termini: {selectedCards}
      </button>
    </div>
  );
};
