const Alert = ({ message, type, closeAlert }) => (
  <div
    style={{
      position: "fixed",
      top: "20px",
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 1000,
      padding: "15px 25px",
      minWidth: "300px",
      maxWidth: "500px",
      textAlign: "center",
      backgroundColor:
        type === "success"
          ? "#4caf50" // Green for success
          : type === "error"
          ? "#f44336" // Red for error
          : "#2196f3", // Blue for info
      color: "white",
      fontSize: "16px",
      fontWeight: "bold",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    }}
  >
    {message}
  </div>
);

export default Alert;
