import React, { useState, useEffect } from "react";
import ApiMethods from "../../../../ApiManager/ApiMethods";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import HistoryIcon from "@mui/icons-material/History";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import BookingCard from "../components/bookingcard";
import { useNavigate } from "react-router-dom";

export const ScheduleModal = ({
  appointments = [],
  closePopup,
  updateStateReservation,
  checkCard,
}) => {
  const navigate = useNavigate();

  const [selectedAppointments, setSelectedAppointments] =
    useState(appointments);
  const addBooking = async () => {
    try {
      const timeSlots = Object.keys(selectedAppointments).map(
        (appointment) => ({
          client_id: localStorage.getItem("id"),
          status: "book",
          timeSlot_id: selectedAppointments[appointment].id,
        })
      );

      const data = {
        timeSlots: timeSlots,
      };
      const response = await ApiMethods.post("auth/add_booking", data);
      console.log("ovo je response", response);
      updateStateReservation();

      closePopup();
    } catch (err) {
      closePopup();
    }
  };

  return (
    <div className="fullscreen-overlay">
      <div className="appointment-popup">
        <div className="history-bookings">
          <div className="history-header-1">
            <HistoryIcon style={{ color: "white" }} />
            <span className="history-header">Pregled termina </span>
          </div>

          <div className="circle">
            <ArrowBackIosIcon
              onClick={() => closePopup()}
              style={{ fontSize: "16px", color: "white" }}
            />
          </div>
        </div>
        <div className="my-bookings-grid">
          {Object.keys(selectedAppointments).length ? (
            Object.keys(selectedAppointments).map((booking) => {
              return (
                <BookingCard
                  bookingState={true}
                  booking={selectedAppointments[booking]}
                >
                  <div>Detaljni zakazivanja</div>
                </BookingCard>
              );
            })
          ) : (
            <p style={{ textAlign: "center", color: "white" }}>
              Nijedan izabrani termin
            </p>
          )}
        </div>
        <div className="custom-dialog-footer-2">
          <button
            onClick={closePopup}
            className="custom-dialog-button custom-cancel-button"
          >
            Izadji
          </button>

          <button
            onClick={addBooking}
            className="custom-dialog-button custom-reschedule-button"
          >
            Potvrdi
          </button>
        </div>
      </div>
    </div>
  );
};
