import { useState, useEffect, Fragment } from "react";
import React from "react";

const Button = ({ name, onClick, className }) => {
  return (
    <button
      className={className ? className : "button-hitfit"}
      onClick={onClick}
    >
      {name}
    </button>
  );
};

export default Button;
