import { useState, Fragment } from "react";
import Button from "../../../components/button";
import Input from "../../../components/input";
import validator from "validator";
import axios from "axios";
import React, { useImperativeHandle, forwardRef } from "react";
import ApiMethods from "../../../../ApiManager/ApiMethods";
import SendNotification from "../../../components/pushnotification";
import { requestForToken, onMessageListener } from "../../../../firebase";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../../context/AlertContext";

const Login = ({
  handleToggle,
  checkAlert,
  handleForgotPassword,
  loginVerification,
  handleToggleRegisterNumber,
}) => {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [platform, setPlatform] = useState("");
  const navigate = useNavigate();
  const { showAlert, closeAlert } = useAlert();

  const onLogin = async (e) => {
    await requestForToken();

    setEmailError("");
    setPasswordError("");

    if (!validator.isEmail(email)) {
      setEmailError("Please insert a valid email format!");
      showAlert("Email nije u dobrom formatu", "error");
      return;
    }

    if (password === "") {
      setPasswordError("Please enter a password");
      showAlert("Morate popuniti polje za lozinku", "error");

      return;
    }

    if (password.length < 6) {
      setPasswordError("The password must be 8 characters or longer");
      return;
    }

    try {
      const data = {
        email,
        password,
        token: localStorage.getItem("fcm_token"),
      };

      const response = await ApiMethods.post("auth/client/login", data);

      if (response.token) {
        localStorage.setItem("token", response.token);
        localStorage.setItem("id", response.id);
        localStorage.setItem("firstName", response.first_name);
        localStorage.setItem("lastName", response.last_name);
        localStorage.setItem("guid", response.guid);
        setToken(response.token);

        showAlert("Uspesno logovanje!", "success");

        loginVerification();
      } else {
        throw new Error("Token not found in response");
      }
    } catch (error) {
      showAlert("Greska pri prijavljivanju", "error");
      setEmailError("Invalid credentials");
      setPasswordError("Invalid credentials");
      console.error("Error during login:", error);
    }
  };

  const handleKeyPress = (event) => {
    console.log("fwefweef");
    if (event.key === "Enter") {
      onLogin(event);
    }
  };

  const validateEmail = (e) => {
    setEmail(e.target.value);
  };

  const validatePassword = (e) => {
    setPassword(e.target.value);
  };

  const onkeydown = (event) => {
    if (event.key === "Enter") {
      onLogin();
    }
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100%",
        position: "relative",
      }}
      onKeyDown={onkeydown}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "50px",
          }}
        >
          <p className="register-part-par">Dobrodošli</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            position: "relative",
          }}
        >
          <Input
            label={"Email"}
            error={emailError}
            placeholder={"Email"}
            id={"email"}
            // type={"email"}
            // nameInput={"email"}
            onChange={validateEmail}
          />
          <Input
            label={"Unesite svoju lozinku"}
            error={passwordError}
            placeholder={"Lozinka"}
            id={"password1"}
            type={"password"}
            nameInput={"password1"}
            value={password}
            onChange={validatePassword}
            onKeyDown={handleKeyPress}
          />
        </div>
        <div>
          <div className="forgot-password ">
            <div className="div-forgot-password login-underline">
              <p
                onClick={() => navigate("/signinphone")}
                className="forgot-password-par"
              >
                {" "}
                Prijava telefonom
              </p>
            </div>
            <div className="div-forgot-password login-underline">
              <p
                onClick={() => navigate("/forgotPassword")}
                className="forgot-password-par"
              >
                {" "}
                Zaboravili ste lozinku?
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "16px",

              bottom: "70px",
              width: "100%",
            }}
          >
            <Button
              className={"register-button button-hitfit"}
              type="submit"
              name={"Uloguj se"}
              onClick={onLogin}
            />
            <p className="par1-getStarted">
              Nemate nalog?{" "}
              <span
                onClick={() => navigate("/register")}
                style={{ color: "red", fontWeight: "600" }}
              >
                {" "}
                Registruj se
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
