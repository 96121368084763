import React, { useState, useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import ApiMethods from "../../ApiManager/ApiMethods";

const Card = ({
  status,
  appointment,
  rescheduleAppointment,
  checkedCard,
  isFull,
  timeSlot_id,
  showReschedule,
  date,
  time,
  updateStateReservation,
  free_bookings,
  isBooked,
  start,
  checkCard,
  freeAppointments,
  isPastDayAndHour,
}) => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleCardClick = () => {
    setOpenPopup(true);
  };

  const handleCancelBooking = async () => {
    try {
      const data = {
        client_id: localStorage.getItem("id"),
        timeSlot_id: appointment.id,
      };
      await ApiMethods.post("auth/delete_bookings", data);
    } catch (err) {
      console.log(err, "err");
    }

    console.log("Cancel the booking");
    setOpenPopup(false);
    updateStateReservation();
  };

  const handleRescheduleBooking = () => {
    rescheduleAppointment(appointment);
    setOpenPopup(false);
    showReschedule();
  };

  return (
    <>
      {openPopup && (
        <div className="custom-dialog-overlay">
          <ClickAwayListener onClickAway={() => setOpenPopup(false)}>
            <div className="custom-dialog-container">
              <div className="custom-dialog-header">
                <h2 className="custom-dialog-title">Izaberi akciju</h2>
              </div>
              <div className="custom-dialog-body">
                <p className="custom-dialog-text">
                  Vreme: {date} - {time}
                </p>
                <p className="custom-dialog-text">
                  Sta želite da uradite sa svojim terminom?
                </p>
              </div>
              <div className="custom-dialog-footer">
                <button
                  onClick={handleCancelBooking}
                  className="custom-dialog-button custom-cancel-button"
                >
                  Otkazi termin
                </button>
                <button
                  onClick={handleRescheduleBooking}
                  className="custom-dialog-button custom-reschedule-button"
                >
                  Izmeni termin
                </button>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      )}
      <div
        // onClick={() =>
        //   !isBooked && !isMyAppointment && timeSlot.id
        //     ? toggleChecked(timeKey, day, time, date, timeSlot.id)
        //     : null
        // }
        onClick={() =>
          isPastDayAndHour
            ? null
            : appointment.status == "available"
            ? checkCard(appointment.start, appointment.id, appointment)
            : appointment.status == "booked"
            ? handleCardClick()
            : null
        }
        className="cell-status"
        style={{
          fontSize: "10px",
          textTransform: "uppercase",
          fontWeight: "bold",
          height: "100%",

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "background-color 0.3s, transform 0.2s",
          cursor: "pointer",
          color: "#fff",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            opacity: isPastDayAndHour ? 0.3 : isFull && !isBooked ? "0.4" : "1",
            backgroundColor: appointment.backgroundColor,
          }}
          className={
            appointment.checked ? "parent1-selected-card" : "parent1-card"
          }
        >
          {appointment.status === "booked" ? (
            <span className="appointment-title">{appointment.title}</span>
          ) : null}

          {appointment.status == "available" ? (
            <div className={"check-icon"}>
              <DoneIcon
                style={{ color: appointment.checked ? "orange" : "white" }}
                sx={{ fontSize: appointment.checked ? 20 : 25 }}
              />
              <div className="text-status1">
                {[...Array(appointment.free_bookings)].map((_, index) => (
                  <PersonIcon
                    key={index}
                    style={{ color: "black" }}
                    sx={{ fontSize: checkedCard ? 6 : 7 }}
                  />
                ))}
              </div>
            </div>
          ) : appointment.status == "full" ? (
            <div className="text-status1-full">
              <PersonOffIcon style={{ color: "black" }} sx={{ fontSize: 7 }} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Card;
