import React from "react";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import ApiMethods from "../../ApiManager/ApiMethods";

export const Popup = ({
  closePopup,
  onConfirm,
  oldAppointment,
  newAppointment,
  updateStateReservation,
}) => {
  const handleConfirmRescedule = async () => {
    try {
      const data = {
        timeslot_old_id: newAppointment.id,
        timeslot_new_id: oldAppointment.id,
        client_id: localStorage.getItem("id"),
      };
      const response = await ApiMethods.post("auth/reschedule", data);
      closePopup();
      updateStateReservation();
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <div className="popup-container">
      <div className="popup-body">
        <div className="popup-content">
          <p className="par-popup">
            <span> Da li želite da zamenite trenutni</span>
            <br />
            <span>termin sa novim terminom?</span>
          </p>

          <div className="appointment-details">
            <div className="old-appointment">
              <span className="header-details">Stari Termin</span>

              <div>
                <span className="par-popup-details-time"> Vreme:</span>{" "}
                <span className="par-popup-details">
                  {moment(oldAppointment.start).format("YYYY-MM-DD")} -{" "}
                  {moment(oldAppointment.start).format("h:mm A")} do{" "}
                  {moment(oldAppointment.end).format("h:mm A")}
                </span>
              </div>
              <span className="status-gym">Trening</span>
            </div>

            <div className="new-appointment">
              <span className="header-details">Novi termin</span>
              <div>
                <span className="par-popup-details-time"> Vreme:</span>{" "}
                <span className="par-popup-details">
                  {moment(newAppointment.start).format("YYYY-MM-DD")} -{" "}
                  {moment(newAppointment.start).format("h:mm A")} do{" "}
                  {moment(newAppointment.end).format("h:mm A")}
                </span>
              </div>

              <span className="status-gym">Trening</span>
            </div>
          </div>
          <div className="custom-dialog-footer-1">
            <button
              onClick={closePopup}
              className="custom-dialog-button custom-cancel-button"
            >
              Otkazi
            </button>

            <button
              onClick={handleConfirmRescedule}
              className="custom-dialog-button custom-reschedule-button"
            >
              Potvrdi
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
