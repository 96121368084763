import React, { useState, useRef, useEffect } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import ApiMethods from "../../../ApiManager/ApiMethods";
import SidebarComponent from "./SideBar/sidebar";
import { IconButton, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Card from "../../components/card";
import CheckIcon from "@mui/icons-material/Check";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import NotificationManager from "../../components/notification";
import { ScheduleModal } from "./components/ScheduleModal";
import { SchedulePopover } from "./components/SchedulePopover";
import MenuIcon from "@mui/icons-material/Menu";

import AlertComponent from "../../components/alert";
import { Popup } from "../../components/popup";
import MyBookings from "./MyBookings/bookings";
import { useAlert } from "../../context/AlertContext";

const customNavigationPickerProps = {
  leftArrowIcon: (
    <IconButton>
      <CalendarMonthIcon />
    </IconButton>
  ),
  rightArrowIcon: (
    <IconButton>
      <CalendarTodayIcon />
    </IconButton>
  ),
};

export const appointmentStatuses = {
  FULL: "full",
  AVAILABLE: "available",
  BOOKED: "booked",
};

export const appointmentStatusesProps = {
  FULL: {
    backgroundColor: "#fff",
    title: "Popunjeno",
  },
  AVAILABLE: {
    backgroundColor: "#98C3F2",
    title: "",
  },
  BOOKED: {
    backgroundColor: "#357a38",
    title: "Rezervisano",
  },
};

const BookingPage = ({ checkUser }) => {
  const [initialized, setInitialized] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [open, setOpen] = useState(false);

  const schedulerRef = useRef(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [alert, setAlert] = useState();
  const [updateReservation, setUpdateReservation] = useState(false);

  const [selectedCards, setSelectedCards] = useState(0);

  const [appointments, setAppointments] = useState([]);

  const [selectedCardByDay, setSelectedCardByDay] = useState({});
  const [myBookedAppointments, setMyBookedAppointments] = useState([]);

  const [rescheduledAppointment, setReschedulingAppointment] = useState([]);
  const [appointmentToReschedule, setAppointmentToReschedule] = useState([]);
  const [showReschedule, setShowReshedule] = useState(false);
  const [formattedStart, setFormatedStart] = useState(
    moment().startOf("week").format("DD")
  );
  const [formattedEnd, setFormatedEnd] = useState(
    moment().endOf("week").format("DD")
  );

  const [formattedMonth, setFormatedMonth] = useState(
    moment().startOf("week").format("MMMM")
  );
  const { showAlert, closeAlert } = useAlert();

  const selectedAppointments = Object.fromEntries(
    Object.entries(appointments).filter(
      ([key, appointment]) => appointment.checked
    )
  );

  const logOut = () => {
    localStorage.clear();
    checkUser();
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleFetchBookings = async () => {
    const data = {
      client_id: localStorage.getItem("id"),
    };
    // const appointmentsResponse = await ApiMethods.get("auth/bookings");
    let startOfWeek = moment(selectedDate).startOf("week"); // Start of the week based on selected date
    let startDate = startOfWeek.format("YYYY-MM-DD");
    const appointmentsResponse = await ApiMethods.get(
      `auth/bookings_list?start=${startDate}`
    );

    const myAppointmentsResponse = await ApiMethods.post(
      "auth/client_bookings",
      data
    );
    console.log(myAppointmentsResponse, "Myapp");
    console.log(appointmentsResponse, "app");

    const tempAppointments = appointmentsResponse.map((appointment) => {
      const tempAppointment = {
        id: appointment.id,
        start: moment
          .parseZone(appointment.start)
          .format("YYYY-MM-DD HH:mm:ss"),
        end: moment.parseZone(appointment.end).format("YYYY-MM-DD HH:mm:ss"),
        max_bookings: appointment.max_bookings,
        used_bookings: appointment.used_bookings,
        free_bookings: appointment.free_bookings,
        status: null,
        checked: false,
      };

      const isMyAppointment =
        myAppointmentsResponse.findIndex((ma) => ma.id === appointment.id) > -1;

      if (isMyAppointment) {
        console.log("jeste");
        tempAppointment.status = appointmentStatuses.BOOKED;
        tempAppointment.backgroundColor =
          appointmentStatusesProps.BOOKED.backgroundColor;
        tempAppointment.title = appointmentStatusesProps.BOOKED.title;
        setMyBookedAppointments((prevAppointments) => [
          ...prevAppointments,
          tempAppointment,
        ]);
        return tempAppointment;
      }

      if (tempAppointment.free_bookings === 0) {
        tempAppointment.status = appointmentStatuses.FULL;
        tempAppointment.backgroundColor =
          appointmentStatusesProps.FULL.backgroundColor;
        tempAppointment.title = appointmentStatusesProps.FULL.title;
        return tempAppointment;
      }

      if (tempAppointment.free_bookings > 0 && !isMyAppointment) {
        tempAppointment.status = appointmentStatuses.AVAILABLE;
        tempAppointment.backgroundColor =
          appointmentStatusesProps.AVAILABLE.backgroundColor;
        tempAppointment.title = appointmentStatusesProps.AVAILABLE.title;
        return tempAppointment;
      }

      return tempAppointment;
    });

    const appointmentsObject = tempAppointments.reduce((acc, appointment) => {
      acc[appointment.start] = appointment;
      return acc;
    }, {});

    setAppointments(appointmentsObject);

    setInitialized(true);
  };

  const checkCard = (key, id, appointment) => {
    const date = moment(key).format("YYYY-MM-DD");
    const exist = Object.values(myBookedAppointments).find(
      (myBookedAppointment) => {
        const startDateAppointment = moment(myBookedAppointment.start).format(
          "YYYY-MM-DD"
        );
        return startDateAppointment === date;
      }
    );
    if (exist && !Object.keys(rescheduledAppointment).length) {
      return exist;
    }
    if (Object.keys(rescheduledAppointment).length) {
      console.log("ovo je appointment", appointment);
      setAppointmentToReschedule(appointment);
      closeAlert();
      setShowReshedule(false);

      setOpen(true);
    } else {
      Object.keys(appointments).forEach((key) => {
        if (key.startsWith(date) && appointments[key].id != id) {
          appointments[key].checked = false;
        }
      });
      appointments[key].checked = !appointments[key].checked;
      setAppointments({ ...appointments });
    }
  };

  const updateStateReservation = async () => {
    console.log("saooo sadasdasd");
    setInitialized(false);
    setReschedulingAppointment([]);
    setMyBookedAppointments([]);
    await handleFetchBookings();
  };

  useEffect(() => {
    if (schedulerRef.current && schedulerRef.current.scheduler) {
      schedulerRef.current.scheduler.handleState(selectedDate, "selectedDate");
    }
    handleFetchBookings();
  }, [selectedDate]);

  const rescheduleAppointment = async (appointment) => {
    setReschedulingAppointment(appointment);
    console.log(appointment);
  };

  const handleNavigate = (direction) => {
    let newDate;
    if (direction === "next") {
      console.log("nesto");
      newDate = moment(selectedDate).add(1, "week").toDate();

      const startOfWeek = moment(newDate).startOf("week").format("DD");
      const endOfWeek = moment(newDate).endOf("week").format("DD");
      setFormatedStart(startOfWeek);
      setFormatedEnd(endOfWeek);
    } else if (direction === "prev") {
      newDate = moment(selectedDate).subtract(1, "week").toDate();

      const startOfWeek = moment(newDate).startOf("week").format("DD");
      const endOfWeek = moment(newDate).endOf("week").format("DD");
      setFormatedStart(startOfWeek);
      setFormatedEnd(endOfWeek);
    }
    setSelectedDate(newDate);
    if (schedulerRef.current) {
      schedulerRef.current.scheduler.selectedDate = newDate;
    }
  };
  useEffect(() => {
    if (showReschedule) {
      showAlert("Izaberite novi termin", "info"); // Show the info alert
    }

    // Optionally close the alert when showReschedule changes to false
    if (!showReschedule) {
      closeAlert(); // Close the alert if it's not needed anymore
    }
  }, [showReschedule, showAlert, closeAlert]);
  const cellRender = (props) => {
    if (!initialized) {
      return null;
    }
    const { start, day, end } = props;
    const formatedStart = moment(start).format("YYYY-MM-DD HH:mm:ss");
    const currentAppointment = appointments[formatedStart];
    if (!currentAppointment) {
      return null;
    }
    const timeKey = start.getTime();
    const dayKey = day.toLocaleDateString();

    let timeSlot = -1;

    const startHour = `${String(start.getHours()).padStart(2, "0")}:${String(
      start.getMinutes()
    ).padStart(2, "0")}`;
    const endHour = `${String(end.getHours()).padStart(2, "0")}:${String(
      end.getMinutes()
    ).padStart(2, "0")}`;

    const date = day.toLocaleDateString();
    const time = `${startHour} - ${endHour}`;
    const isPastDayAndHour = moment(start)
      .startOf("hour")
      .isBefore(moment().startOf("hour"));

    if (["14:00", "13:00", "12:00"].includes(startHour)) {
      return null;
    }

    return (
      <Card
        isPastDayAndHour={isPastDayAndHour}
        rescheduleAppointment={rescheduleAppointment}
        freeAppointments={appointments}
        checkCard={checkCard}
        showReschedule={() => setShowReshedule(true)}
        appointment={currentAppointment}
        checkedCard={selectedCardByDay[dayKey] === timeKey}
        date={date}
        time={time}
        updateStateReservation={updateStateReservation}
        updateReservation={updateReservation}
      />
    );
  };

  return (
    <div>
      <div className="nav-calendar-wrapper">
        <IconButton
          style={{
            color: "white",
            width: "22px",
            height: "12px",
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setIsSidebarOpen(true)}
        >
          <MenuIcon style={{ fontSize: "24px" }} />
        </IconButton>

        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div onClick={() => handleNavigate("prev")}>
            <ArrowBackIosIcon style={{ fontSize: "14px", color: "white" }} />
            <span className="nav-date">{formattedStart}</span>
          </div>

          <span className="nav-date">{formattedMonth}</span>

          <div onClick={() => handleNavigate("next")}>
            <span className="nav-date">{formattedEnd}</span>
            <ArrowForwardIosIcon style={{ fontSize: "14px", color: "white" }} />
          </div>
        </div>
        <CalendarMonthIcon style={{ color: "white", fontSize: "20px" }} />
      </div>

      <div className="booking-page-container">
        {isModalVisible && (
          <ScheduleModal
            checkCard={checkCard}
            updateStateReservation={updateStateReservation}
            appointments={selectedAppointments}
            closePopup={() => setIsModalVisible(false)}
          />
        )}
        {isSidebarOpen ? (
          <SidebarComponent toggleSidebar={toggleSidebar} logOut={logOut} />
        ) : null}

        <div className="booking-page" style={{ zIndex: "0" }}>
          <NotificationManager />

          {open ? (
            <Popup
              newAppointment={rescheduledAppointment}
              oldAppointment={appointmentToReschedule}
              updateStateReservation={updateStateReservation}
              closePopup={() => setOpen(false)}
            />
          ) : null}
          <Scheduler
            view="week"
            ref={schedulerRef}
            navigation={false}
            customNavigationPickerProps={customNavigationPickerProps}
            week={{
              startHour: 8,
              endHour: 22,
              weekStartOn: 1,
              disableGoToDay: true,

              weekDays: [0, 1, 2, 3, 4, 5],
              cellRenderer: cellRender,
            }}
            selectedDate={selectedDate}
            disableViewNavigator={true}
            hourFormat="24"
          />

          <SchedulePopover
            selectedCards={Object.keys(selectedAppointments).length}
            setShowModal={setIsModalVisible}
          />
        </div>
      </div>
    </div>
  );
};

export default BookingPage;
