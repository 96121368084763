import react from "react";
import Button from "../../../components/button";
import { useNavigate } from "react-router-dom";

const GetStarted = ({ handleToggle }) => {
  const navigate = useNavigate();

  return (
    <div className="get-started" style={{ overflow: "hidden" }}>
      <div className="hitfit-start-image">
        <img src="hitfit-get-started.png" />
      </div>
      <div className="last-part-get-started">
        <Button onClick={() => navigate("/register")} name={"Započnite"} />
        <p className="par1-getStarted">
          Imate profil?
          <span
            onClick={() => navigate("/login")}
            style={{ color: "red", fontWeight: "600" }}
          >
            {" "}
            Ulogujte se
          </span>
        </p>
      </div>
    </div>
  );
};

export default GetStarted;
