import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import HistoryIcon from "@mui/icons-material/History";
import { isMobile } from "react-device-detect";
import { IconButton, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

const SidebarComponent = ({ logOut, isModalVisible, toggleSidebar }) => {
  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // const toggleSidebar = () => {
  //   setIsSidebarOpen(!isSidebarOpen);
  // };

  // Sidebar styles
  const sidebarStyles = {
    position: "fixed",
    overflow: "auto",
    top: "0",
    left: "0",
    width: "200px", // Adjust the width of the sidebar
    height: "100%",
    backgroundColor: "#fff",
    boxShadow: "2px 0 5px rgba(0, 0, 0, 0.5)",
    transform: "translateX(0)",
    transition: "transform 0.3s ease-in-out",
    backgroundColor: "#181617",
  };

  // Content styles
  const contentStyles = {
    padding: "20px",
  };

  // Close button styles
  const closeButtonStyles = {
    display: "flex",
    marginBottom: "25px",
    justifyContent: "space-between",
  };

  // Menu item styles
  const menuItemStyles = {
    padding: "10px",
    cursor: "pointer",
  };

  const renderSideBar = () => {
    if (isMobile) {
      return (
        <ClickAwayListener onClickAway={toggleSidebar}>
          <div style={{ zIndex: "1", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                marginTop: "20px",
              }}
            >
              <div
                onClick={toggleSidebar}
                style={{ position: "absolute", left: "16px", color: "black" }}
              >
                <MenuIcon color="black" />
              </div>
            </div>

            <div style={sidebarStyles}>
              <div>
                <div>
                  <div className="header-sidebar" onClick={toggleSidebar}>
                    <img className="logo-hitfit" src={"img/hitfitlogo.jpg"} />
                    <CloseIcon sx={{ color: "white" }} />
                  </div>

                  <div className="profile-section">
                    <div className="avatar-placeholder"></div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span className="user-name">{firstName}</span>
                      <span className="user-name">{lastName}</span>
                    </div>
                  </div>

                  <ul className="menu">
                    <div className="sidebar-icons-div">
                      <EditIcon
                        style={{
                          color: "white",
                          height: "24px",
                          width: "24px",
                        }}
                      />
                      <li
                        className="menu-item"
                        // onClick={() => (window.location.href = "/edit_profile")}
                      >
                        Uredi profil
                      </li>
                    </div>
                    <div className="sidebar-icons-div">
                      <CalendarMonthIcon
                        style={{
                          color: "white",
                          height: "24px",
                          width: "24px",
                        }}
                      />

                      <li
                        className="menu-item"
                        onClick={() => (window.location.href = "/my-bookings")}
                      >
                        Moji termini
                      </li>
                    </div>
                    <div className="sidebar-icons-div">
                      <LogoutIcon
                        style={{
                          color: "white",
                          height: "24px",
                          width: "24px",
                        }}
                      />
                      <li className="menu-item" onClick={logOut}>
                        Odjavi se
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ClickAwayListener>
      );
    }

    return (
      <div className="sidebar">
        <div className="profile-section">
          <h2 className="user-name">
            {firstName} {lastName}
          </h2>
        </div>
        <ul className="menu">
          <div className="sidebar-icons-div">
            <EditIcon />
            <li
              className="menu-item"
              onClick={() => console.log("Edit Profile")}
            >
              Uredi profil
            </li>
          </div>
          <div className="sidebar-icons-div">
            <HistoryIcon />

            <li
              className="menu-item"
              onClick={() => (window.location.href = "/my-bookings")}
            >
              Moja zakazivanja
            </li>
          </div>
          <div className="sidebar-icons-div">
            <LogoutIcon />
            <li className="menu-item" onClick={logOut}>
              Odjavi se
            </li>
          </div>
        </ul>
      </div>
    );
  };

  return renderSideBar();
};

export default SidebarComponent;
