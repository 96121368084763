import { useState, Fragment } from "react";
import React from "react";
import axios from "axios";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Alert from "@mui/material/Alert";
import validator from "validator";
import ApiMethods from "../../../../ApiManager/ApiMethods";
import { useNavigate } from "react-router-dom";

import { useAlert } from "../../../context/AlertContext";

const RegisterNumber = ({ handleToggle }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [value, setValue] = useState();
  const navigate = useNavigate();
  const { showAlert, closeAlert } = useAlert();

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleRegisterWithPhone = async () => {
    setMessage(null);
    setError(null);

    // if (validator.isEmail(email)) {
    //   // setEmailError("Valid Email :)");
    // } else {
    //   setError("Please insert right email format!");
    //   setLoading(false);
    //   return;
    // }
    if (phoneNumber === "") {
      showAlert("Morate uneti broj", "error");
      setLoading(false);

      return;
    }

    if (
      !phoneNumber.startsWith("+") ||
      phoneNumber.length < 4 ||
      isNaN(phoneNumber.slice(1))
    ) {
      showAlert("Broj mora početi sa '+' i biti u ispravnom formatu", "error");
      setLoading(false);
      return;
    }

    try {
      const data = {
        phone: phoneNumber,
      };
      console.log(data);
      const response = await ApiMethods.post(
        "auth/client/register_phone",
        data
      );
      // console.log(response, "ovo je response");

      setMessage("Register  link has been sent to your phone number.");
      showAlert("Link za registraciju je poslat na vas telefon!", "success");
    } catch (error) {
      setError(error, "error");
      showAlert("Greska", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ backgroundColor: "black", height: "100vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
          flexDirection: "column",
        }}
      >
        <p className="register-part-par">Registracija brojem</p>

        <span className="par-pass">
          Unesite svoj broj telefona kako biste dobili verifikacioni kod za
          potvrdu.
        </span>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <Input
          label="Phone"
          placeholder={"+381123456789"}
          type="phone"
          value={phoneNumber}
          onChange={handlePhoneChange}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px",
          position: "absolute",
          bottom: "70px",
          width: "100%",
        }}
      >
        <Button
          className={"register-button button-hitfit"}
          name={loading ? "Salje se..." : "Potvrdi"}
          onClick={handleRegisterWithPhone}
          disabled={loading || !phoneNumber}
          type="button"
        />
        <p className="par1-getStarted">
          Imate profil?{" "}
          <span
            onClick={() => navigate("/login")}
            style={{ color: "red", fontWeight: "600" }}
          >
            {" "}
            Ulogujte se
          </span>
        </p>
      </div>
    </div>
  );
};

export default RegisterNumber;
