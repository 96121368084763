// Updated MyBookings Component
import ApiMethods from "../../../../ApiManager/ApiMethods";
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { EventNote, People, ArrowBack } from "@mui/icons-material";
import { Link } from "react-router-dom";
import HistoryIcon from "@mui/icons-material/History";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import BookingCard from "../components/bookingcard";
import { useNavigate } from "react-router-dom";

const MyBookings = () => {
  const [myBookingsList, setMyBookings] = useState([]);

  const formatTime = (date) => {
    return format(date, "hh:mm a");
  };
  const navigate = useNavigate();

  useEffect(() => {
    myBookings();
  }, []);

  const myBookings = async () => {
    const clientId = localStorage.getItem("id");
    if (!clientId) {
      console.error("Client ID not found in localStorage.");
      return;
    }

    const data = { client_id: clientId };
    try {
      const response = await ApiMethods.post("auth/history_bookigs", data);
      setMyBookings(response);
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  return (
    <div className="my-bookings-wrapper">
      <div className="history-bookings">
        <div className="history-header-1">
          <HistoryIcon style={{ color: "white" }} />
          <span className="history-header">Istorija zakazivanja</span>
        </div>

        <div className="circle">
          <ArrowBackIosIcon
            onClick={() => navigate("/")}
            style={{ fontSize: "16px", color: "white" }}
          />
        </div>
      </div>

      {myBookingsList.length > 0 ? (
        <div className="my-bookings-grid">
          {myBookingsList.map((booking) => {
            return <BookingCard booking={booking} />;
          })}
        </div>
      ) : (
        <p className="no-bookings">Nema zakazivanja.</p>
      )}
    </div>
  );
};

export default MyBookings;
