import { useState, Fragment } from "react";
import React from "react";
import axios from "axios";
import Button from "../../../components/button";
import Input from "../../../components/input";
import Alert from "@mui/material/Alert";
import validator from "validator";
import ApiMethods from "../../../../ApiManager/ApiMethods";
import { useAlert } from "../../../context/AlertContext";

const ForgotPassword = ({ handleToggle }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const { showAlert, closeAlert } = useAlert();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgotPassword = async () => {
    setLoading(true);
    setMessage(null);
    setError(null);

    if (validator.isEmail(email)) {
      // setEmailError("Valid Email :)");
    } else {
      setError("Please insert right email format!");
      showAlert("Email nije u dobrom formatu", "error");
      setLoading(false);
      return;
    }

    try {
      const data = {
        email: email,
      };
      const response = await ApiMethods.post(
        "auth/client/forgot-password/send-mail",
        data
      );
      setMessage("Password reset link has been sent to your email.");
      showAlert("Password reset link has been sent to your email.", "success");
    } catch (error) {
      setError("Email doesnt exist");
      showAlert("Email doesnt exist", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <div style={{ backgroundColor: "black", height: "100vh" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="register-part-par"> Zaboravili ste lozinku?</p>
        </div>
        <p className="par-pass">
          Poslaćemo vam email sa verifikacionim kodom za promenu lozinke.
        </p>
        <div>
          <Input
            label="Email"
            placeholder="Unesite vas email"
            type="email"
            value={email}
            onChange={handleEmailChange}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "16px",
            position: "absolute",
            bottom: "130px",
            width: "100%",
          }}
        >
          <Button
            className={"register-button button-hitfit"}
            type="submit"
            name={loading ? "Salje se..." : "Posalji"}
            onClick={handleForgotPassword}
            disabled={loading || !email}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPassword;
