import { useState, Fragment } from "react";
import React from "react";
import Button from "../../../components/button";
import validator from "validator";
import Input from "../../../components/input";
import axios from "axios";
import ApiMethods from "../../../../ApiManager/ApiMethods";
import { useAlert } from "../../../context/AlertContext";
import { useNavigate } from "react-router-dom";

const Register = ({ handleToggle }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");

  const [lastName, setLastName] = useState("");
  const [alert, setAlert] = useState();

  const [password, setPassword] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPhone, setPhoneError] = useState("");
  const { showAlert, closeAlert } = useAlert();
  const navigate = useNavigate();

  const validatePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const validateEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const validateFirstName = (e) => {
    const firstName = e.target.value;
    setFirstName(firstName);
  };

  const validateLastName = (e) => {
    const lastName = e.target.value;
    setLastName(lastName);
  };
  const validateConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);

    // Example password validation criteria
  };
  const validatePhone = (e) => {
    const phone = e.target.value;
    setPhone(phone);

    // Example password validation criteria
  };

  const onRegister = async (e) => {
    setEmailError("");
    setPasswordError("");
    setFirstNameError("");
    setLastNameError("");
    console.log(firstName);
    console.log("nestoo");
    console.log(password, "pass");

    if (firstName === "") {
      console.log("usao sam ovde");
      setFirstNameError("Please enter first name");
      showAlert("Polje za ime je prazno", "error");

      return;
    }

    if (lastName === "") {
      setLastNameError("Please enter last name");
      showAlert("Polje za prezime je prazno", "error");

      return;
    }
    // if (phone === "") {
    //   setPhoneError("Please enter phone number");
    //   return;
    // }

    if (!validator.isEmail(email)) {
      setEmailError("Enter a valid email!");
      showAlert("Email nije u dobrom formatu", "error");

      return;
    }

    if (password === "") {
      setPasswordError("Please enter password");
      showAlert("Polje za lozinku je prazno", "error");

      return;
    }

    if (confirmPassword === "") {
      setConfirmPassword("Please enter password");
      showAlert("Polje za lozinku je prazno", "error");

      return;
    }
    if (confirmPassword != password) {
      setConfirmPassword("Passwords do not match.");
      showAlert("Lozinke se ne poklapaju", "error");
      setPasswordError("Passwords do not match.");
      return;
    }

    if (password.length < 6) {
      setPasswordError("The password must be 8 characters or longer");
      showAlert("Lozinka mora imati bar 6 karaktera", "error");
      return;
    }

    console.log("All validations passed");
    try {
      const data = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        // phone: phone,
      };
      const response = await ApiMethods.post("auth/client/register", data);

      console.log("Registration successful", response.data);
      showAlert(
        "Link za verifikaciju je poslat na vašu email adresu.",
        "success"
      );
      navigate("/login");
    } catch (error) {
      setEmailError("User already exist");
      showAlert(error.message, "error");
      console.error("Error during registration:", error);
    }
  };
  const onkeydown = (event) => {
    if (event.key === "Enter") {
      onRegister();
    }
  };

  // const validatePhoneNumber = (e) => {
  //   const value = e.target.value;
  //   setPhone(value);

  //   // Check if the phone number starts with +381
  //   if (!value.startsWith("+381")) {
  //     setError("Phone number must start with +381");
  //   } else {
  //     setError(""); // Clear error if valid
  //   }
  // };
  const checkAlert = (validCredentials) => {
    setAlert(validCredentials);
    setTimeout(() => {
      setAlert(undefined);
    }, 800);
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        height: "100%",
      }}
    >
      <div onKeyDown={onkeydown}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p className="register-part-par"> Welcome</p>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Input
            label={"Ime"}
            placeholder={"Ime"}
            id={"firstName"}
            type={"text"}
            nameInput={"firstName"}
            error={firstNameError}
            onChange={validateFirstName}
          />
          <Input
            label={"Prezime"}
            error={lastNameError}
            placeholder={"Prezime"}
            id={"lastName"}
            type={"text"}
            nameInput={"lastName"}
            onChange={validateLastName}
          />
          {/* <Input
          label={"Phone number"}
          error={confirmPhone}
          placeholder={"+381123456789"}
          id={"phone"}
          type={"phone"}
          nameInput={"phone"}
          onChange={validatePhone}
        /> */}
          <Input
            label={"Email"}
            error={emailError}
            placeholder={"Email"}
            id={"email1"}
            type={"email"}
            nameInput={"email1"}
            onChange={validateEmail}
          />
          <Input
            label={"Lozinka"}
            error={passwordError}
            placeholder={"Lozinka"}
            id={"password1"}
            type={"password"}
            nameInput={"password1"}
            value={password}
            onChange={validatePassword}
          />
          <Input
            label={"Potvrda lozinke"}
            error={passwordError}
            placeholder={"Potvrda lozinke"}
            id={"confirm"}
            type={"password"}
            nameInput={"confirm"}
            value={password}
            onChange={validateConfirmPassword}
          />
        </div>
        <div
          onClick={() => navigate("/forgotPassword")}
          className="div-forgot-password"
        >
          <p className="forgot-password-par"> Zaboravili ste lozinku?</p>
        </div>
        <div
          className="register-div-button button-new"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className={"register-button button-hitfit"}
            type="submit"
            name={"Registruj se"}
            onClick={onRegister}
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
