import React, { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Input = ({
  placeholder,
  id,
  nameInput,
  type,
  onChange,
  label,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  // Toggle the visibility of the password
  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <div className="label-input" style={{ position: "relative" }}>
      <label className="label-input-1">{label}</label>
      <input
        style={{ position: "relative" }}
        id={id}
        name={nameInput}
        type={type === "password" && showPassword ? "text" : type}
        placeholder={placeholder}
        className="tm-input-hit"
        required
        onChange={onChange}
      />
      {type === "password" && (
        <span
          onClick={togglePasswordVisibility}
          style={{
            position: "absolute",
            right: "25px",
            top: "50%",
            transform: "translateY(-10%)",
            cursor: "pointer",
            userSelect: "none",
          }}
        >
          {showPassword ? (
            <VisibilityOffIcon style={{ color: "white", fontSize: 20 }} />
          ) : (
            <VisibilityIcon style={{ color: "white", fontSize: 20 }} />
          )}{" "}
          {/* Eye icons */}
        </span>
      )}
      {/* <div
        style={{
          minHeight: "20px" ,
          color: "red",
          fontSize: "14px",
          marginTop: "5px",
        }}
      >
        {error}
      </div> */}
    </div>
  );
};

export default Input;
