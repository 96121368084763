import React, { createContext, useState, useContext, useCallback } from "react";
import Alert from "../components/alertv2";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);

  const showAlert = useCallback((message, type = "info") => {
    setAlert({ message, type });

    if (type !== "info") {
      setTimeout(() => setAlert(null), 2000); // Auto-close for non-info alerts
    }
  }, []);

  const closeAlert = useCallback(() => {
    setAlert(null);
  }, []);

  return (
    <AlertContext.Provider value={{ showAlert, closeAlert }}>
      {children}
      {alert && (
        <Alert
          message={alert.message}
          type={alert.type}
          closeAlert={alert.type === "info" ? closeAlert : null} // Pass closeAlert only for "info" type
        />
      )}
    </AlertContext.Provider>
  );
};

export const useAlert = () => useContext(AlertContext);
